import * as PusherPushNotifications from "@pusher/push-notifications-web"
import { getToken, onMessage } from "firebase/messaging"

import store from "@/store"
import router from "@/router"

import EventBus, { EVENTS } from "@/lib/event-bus"
import { isNativePush, isSupportedDevice } from "@/lib/is-platform"
import {
  setupPushStatusListener,
  postMessageToNativeApp,
  buildMessage,
  openSettings,
  MESSAGE_TYPES
} from "@/lib/app-store-utils"
import {
  PROVIDERS,
  NOTIFICATION_GRANTED,
  STATUSES
} from "@/store/notifications"
import { getPathFromUrl, getQueryParam } from "@/lib/url-helpers"
import messaging from "@/lib/fcm/messaging"

let beamsClient = null

const isPushEnabled = () => store.getters["notifications/isPushEnabled"]()
const isSuspended = () => store.getters["notifications/isPushSuspended"]()

const getBeamsClient = async () => {
  if (beamsClient) return beamsClient

  try {
    let swRegistration = null

    if ("serviceWorker" in navigator) {
      swRegistration = await navigator.serviceWorker.getRegistration()
    }

    beamsClient = new PusherPushNotifications.Client({
      instanceId: process.env.VUE_APP_PUSHER_INSTANCE_ID,
      serviceWorkerRegistration: swRegistration
    })

    return beamsClient
  } catch (e) {
    // Unable to load beams
  }
}

const updatePushState = async (canAsk, status, token, provider, deviceId) => {
  store.commit("notifications/setCanAsk", canAsk)
  store.commit("notifications/setStatus", status)
  store.commit("notifications/setToken", token)
  store.commit("notifications/setProvider", provider)
  store.commit("notifications/setPusherDeviceId", deviceId)

  await store.dispatch("notifications/updatePushEndpointFromState")
}

const setupWebPushNotifications = () => {
  if (Notification?.permission === NOTIFICATION_GRANTED) {
    onMessage(messaging, async payload => {
      // Prevents multiple notifications from appearing when the app is in the foreground
      if (document.visibilityState === "hidden") return

      try {
        const notification = new Notification(payload.notification.title, {
          ...payload.notification,
          data: { link: payload.fcmOptions.link }
        })

        // Handle click event when the app is foregrounded
        notification.addEventListener("click", e => {
          handleNotificationUrl(e.target.data.link)

          window.focus()
        })
      } catch (e) {
        // Notification failed to display
        if (e.name === "TypeError" && "serviceWorker" in navigator) {
          const swRegistration = await navigator.serviceWorker.getRegistration()

          if (swRegistration) {
            swRegistration.showNotification(payload.notification.title, {
              ...payload.notification,
              data: { link: payload.fcmOptions.link }
            })
          } else {
            console.error(e)
          }
        } else {
          console.error(e)
        }
      }
    })
  }
}

const updateTokenAndMarkEnabled = async () => {
  const prevProvider = store.state.notifications.provider

  const token = await getToken(messaging)

  await updatePushState(false, STATUSES.ENABLED, token, PROVIDERS.FCM)

  if (prevProvider === PROVIDERS.PUSHER) {
    unsubscribeFromBeams()
  }
}
export const handleNotificationUrl = url => {
  const blockActionQueryParam = getQueryParam(url, "block-action")

  if (blockActionQueryParam) {
    EventBus.$emit(EVENTS.TRIGGER_BLOCK_ACTION, blockActionQueryParam)
  } else {
    const path = getPathFromUrl(url, true)

    if (path !== "/") router.push(path)
  }
}

export const initializePushNotifications = async () => {
  if (isNativePush) {
    setupPushStatusListener(status => {
      updatePushState(
        status.canAsk,
        status.granted ? STATUSES.ENABLED : STATUSES.DISABLED,
        status.token,
        PROVIDERS.EXPO
      )
    })
  } else if (isSupportedDevice()) {
    await store.dispatch("notifications/fetchPushSettings")

    if (isPushEnabled() || isSuspended()) {
      updateTokenAndMarkEnabled()

      setupWebPushNotifications()
    } else {
      updatePushState(true, STATUSES.DISABLED, null, PROVIDERS.FCM)
    }
  }

  await queryPushStatus()
}

export const queryPushStatus = () =>
  new Promise(resolve => {
    if (isNativePush) {
      postMessageToNativeApp(buildMessage(MESSAGE_TYPES.PUSH_STATUS_REQUEST))
      setTimeout(resolve, 1250)
    } else {
      if (store.getters["notifications/hasLoadedPushSettings"]) {
        resolve()
      } else {
        store.dispatch("notifications/fetchPushSettings").then(resolve)
      }
    }
  })

const unsubscribeFromBeams = () =>
  getBeamsClient().then(client => client?.stop())

export const requestPermission = () =>
  new Promise((resolve, reject) => {
    if (isNativePush) {
      postMessageToNativeApp(
        buildMessage(MESSAGE_TYPES.PUSH_REQUEST_PERMISSION)
      )

      setTimeout(() => {
        if (isPushEnabled()) {
          resolve()
        } else {
          reject()
        }
      }, 3000)
    } else if (isSupportedDevice()) {
      Notification.requestPermission()
        .then(permission => {
          if (permission === NOTIFICATION_GRANTED) {
            updateTokenAndMarkEnabled()
            setupWebPushNotifications()
            resolve()
          } else {
            reject()
          }
        })
        .then(resolve)
        .catch(reject)
    } else {
      reject()
    }
  })

export const unsubscribeFromPushNotifications = async () => {
  if (isNativePush) {
    openSettings()
  } else {
    await updatePushState(false, STATUSES.DISABLED, null, PROVIDERS.FCM)
    unsubscribeFromBeams()
  }
}

export { openSettings, isNativePush, isSupportedDevice }
