<template>
  <div id="app">
    <v-pull-hook />

    <transition name="fade" mode="out-in">
      <keep-alive :max="6" :exclude="['MessageThreadShow']">
        <router-view :key="$route.path">
          <div id="overlay-portal"></div>
          <div id="cta-row-portal"></div>
          <div id="modal-portal"></div>
        </router-view>
      </keep-alive>
    </transition>

    <auth-modal />
    <form-modal />
    <checkout-modal-trigger v-if="!isCheckoutPage" />
    <scheduled-content-modal-trigger />
    <overlay-modal />
    <navigation-page-modal />
    <app-download-bar />
    <tabbar />
    <block-action-trigger />
    <notification-permission-trigger />
    <global-loader-trigger />
    <router-queue-trigger />
    <global-audio-player />
  </div>
</template>

<script>
import { setOptions, bootstrap } from "vue-gtag"

import { mapState, mapActions, mapGetters, mapMutations } from "vuex"

import { NAVIGATION_EVENTS } from "@/plugins/router/shared"

import pwaMeta from "@/lib/pwa-meta"
import appHeightWatcher from "@/lib/app-height-watcher"
import isAppStore from "@/lib/is-platform/is-app-store"
import EventBus, { EVENTS } from "@/lib/event-bus"
import { isCheckoutPage } from "@/lib/checkout-utils"
import { HIDE_QUERY_PARAM } from "@/lib/constants"

export default {
  components: {
    AuthModal: () =>
      import(/* webpackChuckName: "AuthModal" */ "@/components/AuthModal"),
    Tabbar: () =>
      import(/* webpackChuckName: "Tabbar" */ "@/components/Tabbar"),
    FormModal: () => import("@/components/modals/FormModal"),
    NavigationPageModal: () =>
      import("@/components/modals/NavigationPageModal"),
    AppDownloadBar: () => import("@/components/AppDownloadBar"),
    CheckoutModalTrigger: () =>
      import("@/components/checkout/CheckoutModalTrigger"),
    ScheduledContentModalTrigger: () =>
      import("@/components/modals/ScheduledContentModalTrigger"),
    OverlayModal: () => import("@/components/modals/OverlayModal"),
    BlockActionTrigger: () =>
      import("@/components/block-actions/BlockActionTrigger"),
    NotificationPermissionTrigger: () =>
      import("@/components/modals/NotificationPermissionTrigger"),
    GlobalLoaderTrigger: () => import("@/components/GlobalLoaderTrigger"),
    RouterQueueTrigger: () => import("@/components/RouterQueueTrigger"),
    GlobalAudioPlayer: () => import("@/components/audio/GlobalPlayer")
  },

  data() {
    return {
      isInitialized: false
    }
  },

  head() {
    return {
      ...pwaMeta(this.global || {})
    }
  },

  computed: {
    ...mapState(["global"]),
    ...mapGetters(["app"]),
    ...mapGetters("router", ["historyType"]),
    ...mapGetters("auth", ["currentUser"]),

    isCheckoutPage() {
      return isCheckoutPage(this.$route.path)
    }
  },

  methods: {
    ...mapActions("auth", { initializeAuth: "initialize" }),
    ...mapActions("pwa", ["setupInstallPrompt"]),
    ...mapActions("analytics", [
      "trackActivity",
      "trackPagePush",
      "trackPagePop"
    ]),
    ...mapMutations("ui", ["setHiddenElements"]),

    enableGoogleAnalytics(id) {
      setOptions({
        includes: id ? [{ id }] : [],
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
      })

      bootstrap()
    },

    handleCheckoutCompleted() {
      try {
        if (window.rewardful) {
          window.rewardful("convert", { email: this.currentUser?.email })
        }
      } catch (e) {
        console.error(e)
      }
    },

    persistHiddenElements() {
      this.$router.onReady(() => {
        const hiddenElements = (this.$route.query[HIDE_QUERY_PARAM] || "")
          .split(",")
          .filter(Boolean)

        return this.setHiddenElements(hiddenElements)
      })
    }
  },

  mounted() {
    this.initializeAuth()
    this.setupInstallPrompt()
    this.persistHiddenElements()
    this.$router.on(NAVIGATION_EVENTS.PUSH, this.trackPagePush)
    this.$router.on(NAVIGATION_EVENTS.POP, this.trackPagePop)
    appHeightWatcher()

    EventBus.$on(EVENTS.CHECKOUT_COMPLETED, this.handleCheckoutCompleted)

    this.enableGoogleAnalytics(this.app?.google_analytics_id)

    this.$nextTick(() => {
      this.isInitialized = true

      if (isAppStore()) {
        const { initialize } = require("@/lib/app-store-utils")
        initialize(this)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.debug-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: black;
  color: white;
}
</style>
