import ERROR_REASONS from "@shared/payments/error-reasons.json"
import INTENT_TYPES from "@shared/payments/intent-types.json"

import { isAppStore, isIos } from "@/lib/is-platform"
import { queryString } from "@/lib/url-helpers"

export const STAGES = {
  START: "#checkout",
  PAY: "#checkout/pay",
  CALLBACK: "#checkout/callback",
  PAY_IAP: "#checkout/pay/iap",
  ERROR: "#checkout/error",
  PRODUCTS: "#checkout/products",
  REACTIVATE: "#checkout/reactivate",
  SUCCESS: "#checkout/success"
}

export const IAP_ERRORS = {
  INVALID_PRODUCT: "invalid_product"
}

// This is used to allow us to open the CheckoutModal to confirm a setup
// intent callback, without actually setting up with relation to any specific product
// Useful for when you want to just update a payment method using a setupIntent
export const SETUP_INTENT_PRODUCT_ID = "SETUP_INTENT_PRODUCT"
export const BUY_PATH = "/buy/"

export const CALLBACK_PARAMS = {
  AUTH_TOKEN: "token",
  PRODUCT_ID: "product_id",
  SETUP_INTENT: "setup_intent",
  PAYMENT_INTENT_SECRET: "payment_intent_client_secret",
  SETUP_INTENT_SECRET: "setup_intent_client_secret",
  IS_EXPRESS: "express",
  IS_GUEST: "guest"
}

export const generateReturnPath = (path, queryParams = {}) => {
  return path + queryString(queryParams) + STAGES.CALLBACK
}

export const isCheckoutPage = path => path.startsWith(BUY_PATH)
export const shouldUseIAP = () => isAppStore() && isIos()
export const shouldUseIAPForProduct = product =>
  ("use_iap" in (product || {}) ? product.use_iap : true) && shouldUseIAP()

export { ERROR_REASONS, INTENT_TYPES }
