import Vue from "vue"
import VueRouter, { Route, NavigationGuardNext, RouteConfig } from "vue-router"

import DynamicPage from "@/pages/Dynamic.vue"

import store from "@/store"

import scrollBehavior from "@/plugins/router/scroll-behaviour"
import { BUY_PATH } from "@/lib/checkout-utils"
import { MANAGED_PAGE_PREFIX } from "@/lib/constants"

Vue.use(VueRouter)

export const requireAuthentication = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  // vLink or GenericTemplate will take care of showing the auth modal for
  // generic pages
  if (!to.path.startsWith(MANAGED_PAGE_PREFIX)) {
    return next()
  }

  if (store.getters["auth/hasCheckedAuthentication"]) {
    if (store.getters["auth/isAuthenticated"]) return next()

    if (to.hash.startsWith("#auth")) return next()
    if (from.hash.startsWith("#auth")) return next()

    if (to.hash.startsWith("#checkout")) return next()
    if (from.hash.startsWith("#checkout")) return next()

    if (
      to.matched &&
      to.matched[0] &&
      !!MANAGED_PAGES_REQUIRING_AUTH[to.matched[0].path]
    ) {
      return next("#auth")
    } else {
      return next()
    }
  } else {
    setTimeout(() => requireAuthentication(to, from, next), 250)
    return next()
  }
}

const routes: RouteConfig[] = [
  {
    path: `${MANAGED_PAGE_PREFIX}account`,
    component: () => import("@/pages/Account.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}account/details`,
    component: () => import("@/pages/account/Details.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}account/purchases`,
    component: () => import("@/pages/account/Purchases.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}products/:id`,
    component: () => import("@/pages/PurchasedProduct.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}account/notifications`,
    component: () => import("@/pages/account/Notifications.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}account/subscriptions`,
    component: () => import("@/pages/account/Subscriptions.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}account/support`,
    component: () => import("@/pages/account/Support.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}account/privacy`,
    component: () => import("@/pages/account/Privacy.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}messages/new`,
    component: () => import("@/pages/MessageThread/New.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    path: `${MANAGED_PAGE_PREFIX}messages/:id`,
    component: () => import("@/pages/MessageThread/Show.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    path: `${MANAGED_PAGE_PREFIX}messages`,
    component: () => import("@/pages/MessageThread/Index.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    path: `${MANAGED_PAGE_PREFIX}messages/:id/participants`,
    component: () => import("@/pages/MessageThread/Participants.vue")
    // beforeEnter: requireAuthentication // Causing issues not having route params available
  },
  {
    path: "/me/schedule",
    component: () => import("@/pages/Schedule.vue")
  },
  {
    path: "/discover/programs",
    component: () => import("@/pages/Discover.vue")
  },
  {
    path: "/discover/events",
    component: () => import("@/pages/Discover.vue")
  },
  {
    path: "/discover/packs",
    component: () => import("@/pages/Discover.vue")
  },
  {
    path: `${MANAGED_PAGE_PREFIX}my-content`,
    component: () => import("@/pages/MyContent.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}submissions/:formType?`,
    component: () => import("@/pages/submissions/SubmissionsNumber.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}forms/:formId/submissions/:id`,
    component: () => import("@/pages/submissions/FormSubmission.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}forms/:formId/submissions`,
    component: () => import("@/pages/submissions/FormSubmissions.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: `${MANAGED_PAGE_PREFIX}workout/:id/sessions`,
    component: () => import("@/pages/workout/Sessions.vue"),
    beforeEnter: requireAuthentication
  },
  {
    path: "/forms/:id",
    component: () => import("@/pages/Form.vue")
  },
  {
    path: `${BUY_PATH}:ids`,
    component: () => import("@/pages/Buy.vue")
  },
  {
    path: "/subscriber/:id/unsubscribe",
    component: () => import("@/pages/UnsubscribeFromEmail.vue")
  },

  // Redirects
  {
    path: "/discover",
    redirect: "/discover/programs"
  },

  // Account App Pages
  {
    path: "*",
    component: DynamicPage
  }
]

const MANAGED_PAGES_REQUIRING_AUTH = routes
  .filter(
    route => route.path.startsWith(MANAGED_PAGE_PREFIX) && !!route.beforeEnter
  )
  .reduce((acc, route: any): Record<string, any> => {
    acc[route.path] = route
    return acc
  }, {} as Record<string, any>)

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior
})

router.beforeEach(requireAuthentication)

export default router
