import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import duration from "dayjs/plugin/duration"

import { pluralize } from "@/lib/utils"
import i18n from "@/store/i18n"
import "dayjs/locale/nb"
import "dayjs/locale/es"
import "dayjs/locale/nl"

dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(duration)

export const timeAgo = (date, noSuffix = false) => {
  setDayJsLocale()
  return dayjs(date).fromNow(noSuffix)
}
export const toDate = (date, format = "MMM D, YYYY") => {
  setDayJsLocale()
  return dayjs(date).format(format)
}

export const toDateTime = (date, format = "MMM D, YYYY - hh:mm:ss A") => {
  setDayJsLocale()
  return toDate(date, format)
}

export const isPast = date => {
  setDayJsLocale()
  return dayjs().isAfter(dayjs(date))
}

export const isFuture = date => {
  setDayJsLocale()
  return dayjs().isBefore(dayjs(date))
}

export const isBeforeToday = date => isPast(date) && !isSameDay(date)

export const toTime = (date, format = "hh:mm:ss A") => {
  setDayJsLocale()
  return toDate(date, format)
}
export const startOf = (unit, date = new Date()) => {
  setDayJsLocale()
  return dayjs(date)
    .startOf(unit)
    .toDate()
}

export const timeBetween = (date1, date2) => {
  setDayJsLocale()
  const from = dayjs(date1)
  const to = dayjs(date2)

  const secondsBetween = Math.abs(to.diff(from, "seconds"))

  if (secondsBetween < 60) {
    return `${secondsBetween} ${i18n.t("lib.dateUtils.sec")}`
  } else if (secondsBetween < 3600) {
    const minutes = Math.floor(secondsBetween / 60)
    const seconds = String(secondsBetween % 60).padStart(2, "0")

    return `${minutes}${seconds > "00" ? `:${seconds}` : ""} ${i18n.t(
      "lib.dateUtils.min"
    )}`
  } else if (secondsBetween < 216000) {
    const hours = Math.floor(secondsBetween / 3600)
    const minutes = String(Math.floor((secondsBetween / 60) % 60)).padStart(
      2,
      "0"
    )

    return `${hours}${minutes === "00" ? "" : `:${minutes}`} ${pluralize(
      i18n.t("lib.dateUtils.hour"),
      hours
    )}`
  } else if (secondsBetween < 604800) {
    const days = to.diff(from, "days")

    return `${days} ${i18n.t("lib.dateUtils.days")}`
  } else {
    const weeks = to.diff(from, "weeks")

    return `${weeks} ${i18n.t("lib.dateUtils.weeks")}`
  }
}
export const parseEventTime = (
  startsAt,
  endsAt,
  isAllDay = false,
  full = true
) => {
  setDayJsLocale()
  if (isAllDay) {
    return i18n.t("lib.dateUtils.allDay")
  } else {
    if (full) {
      return `${toTime(startsAt, `h:mm A`)} - ${toTime(endsAt, "h:mm A")}`
    } else {
      const formattedTime = `${toTime(
        startsAt,
        `h:mm${full ? " A" : ""}`
      )} - ${toTime(endsAt, "h:mm A")}`
      const timeSplit = formattedTime.split(" - ")
      const formattedParts = timeSplit.map(part => part.replace(":00", ""))

      return formattedParts.join(" - ")
    }
  }
}

export const isWithin = (date, unit, interval, compareTo = dayjs()) => {
  setDayJsLocale()
  const difference = Math.abs(dayjs(date).diff(compareTo, interval))
  return difference <= unit - 1
}

export const secondsUntil = date => {
  setDayJsLocale()
  return dayjs(date).diff(dayjs(), "second")
}

export const isSameDay = (date1, date2 = dayjs()) => {
  setDayJsLocale()
  return toDate(date1) === toDate(date2)
}

export const add = (unit, interval = "day", date = new Date()) => {
  setDayJsLocale()
  return dayjs(date)
    .add(unit, interval)
    .toDate()
}

export const subtract = (unit, interval = "day", date = new Date()) => {
  setDayJsLocale()
  return dayjs(date)
    .subtract(unit, interval)
    .toDate()
}

export const daysBetweenDates = (start, end, format = "YYYY-MM-DD") => {
  setDayJsLocale()
  const dateArray = []
  let currentDate = dayjs(start)

  while (currentDate <= dayjs(end)) {
    dateArray.push(format ? currentDate.format(format) : currentDate.toDate())
    currentDate = currentDate.add(1, "day")
  }

  return dateArray
}

export const dayOfMonth = date => {
  setDayJsLocale()
  return dayjs(date).date()
}
export const getUserTimezone = () => {
  setDayJsLocale()
  return dayjs.tz.guess()
}

const setDayJsLocale = () => {
  dayjs.locale(i18n.locale)
}
